import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"

export const CompareDataDesktop = () => {
    
    return[
        {
            name: 'Outcome driven templates',
            lucidreach: true,
            constantcontact: false,
            mailer: false,
            aweber: false,
            drip: false,
            mailchimp: false,
        },

        {
            name: 'Industry specific Email marketing',
            lucidreach: true,
            constantcontact: false,
            mailer: false,
            aweber: false,
            drip: false,
            mailchimp: false,
        },

        {
            name: 'Plain text templates',
            lucidreach: '400+',
            constantcontact: false,
            mailer: false,
            aweber: false,
            drip: false,
            mailchimp: false,
        },

        {
            name: 'Sign up forms',
            lucidreach: true,
            constantcontact: true,
            mailer: true,
            aweber: true,
            drip: true,
            mailchimp: true,
        }
    ]
}

export const CompareDataMobile = () => {

    const data = useStaticQuery(graphql`
        query ProductLogos {
            
            LucidReach: file(absolutePath: {regex: "/images/other_products/"}, name: {eq: "lucidreach"}) {
                id
                childImageSharp {
                    gatsbyImageData(width:118, quality: 100, placeholder: NONE)
                }
            }
            ConstantContact: file(absolutePath: {regex: "/images/other_products/"}, name: {eq: "constantcontact"}) {
                id
                childImageSharp {
                    gatsbyImageData(width:118, quality: 100, placeholder: NONE)
                }
            }
            Mailer: file(absolutePath: {regex: "/images/other_products/"}, name: {eq: "mailer"}) {
                id
                childImageSharp {
                    gatsbyImageData(width:118, quality: 100, placeholder: NONE)
                }
            }
            Aweber: file(absolutePath: {regex: "/images/other_products/"}, name: {eq: "aweber"}) {
                id
                childImageSharp {
                    gatsbyImageData(width:118, quality: 100, placeholder: NONE)
                }
            }
            Drip: file(absolutePath: {regex: "/images/other_products/"}, name: {eq: "drip"}) {
                id
                childImageSharp {
                    gatsbyImageData(width:118, quality: 100, placeholder: NONE)
                }
            }
            Mailchimp: file(absolutePath: {regex: "/images/other_products/"}, name: {eq: "mailchimp"}) {
                id
                childImageSharp {
                    gatsbyImageData(width:118, quality: 100, placeholder: NONE)
                }
            }
        }
    `
    )

    return [
        {
            name: 'lucidreach',
            image: getImage(data.LucidReach),
            'Outcome driven templates': true,
            'Industry specific Email marketing ': true,
            'Plain text templates': '400+',
            'Sign up forms': true,
            price: '$9',
            contactPricing: '$9 for unlimited contacts'
        },

        {
            name: 'constantcontact',
            image: getImage(data.ConstantContact),
            'Outcome driven templates': false,
            'Industry specific Email marketing ': false,
            'Plain text templates': false,
            'Sign up forms': true,
            price: '$17',
            contactPricing: '$20 additionally for every 2000 contacts'
        },

        {
            name: 'mailer',
            image: getImage(data.Mailer),
            'Outcome driven templates': false,
            'Industry specific Email marketing ': false,
            'Plain text templates': false,
            'Sign up forms': true,
            price: '$50',
            contactPricing: '$15 additionally for every 1500 contacts'
        },

        {
            name: 'aweber',
            image: getImage(data.Aweber),
            'Outcome driven templates': false,
            'Industry specific Email marketing ': false,
            'Plain text templates': false,
            'Sign up forms': true,
            price: '$16',
            contactPricing: '$10 additionally for every 1500 contacts'
        }, 

        {
            name: 'drip',
            image: getImage(data.Drip),
            'Outcome driven templates': false,
            'Industry specific Email marketing ': false,
            'Plain text templates': false,
            'Sign up forms': true,
            price: '$29',
            contactPricing: '$10 additionally for every 1500 contacts'
        }, 

        {
            name: 'mailchimp',
            image: getImage(data.Mailchimp),
            'Outcome driven templates': false,
            'Industry specific Email marketing ': false,
            'Plain text templates': false,
            'Sign up forms': true,
            price: '$10',
            contactPricing: '$35 additionally for every 1500 contacts'
        }
    ]
}
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { BenifitsData } from '../../constantData/BenifitsData'
import RightArrowIcon from '../../images/icons/rightArrow'
import BenifitItem from '../common/BenifitItem'
import SectionHeader from '../common/SectionHeader'

const Benifits:React.FC = () => {

    const benifitsData = BenifitsData()

    return (
        <>
            <section className="" id="benifits">

                {/* MainSection */}
                <div className="mx-auto">
                    {benifitsData.map((benifit, index)=> {
                        return(
                            <div key={benifit.title}>
                                <BenifitItem benifit={benifit} even={index % 2 == 0} />
                            </div>
                        )
                    })}
                </div>
            </section>
        </>
    )
}

export default Benifits

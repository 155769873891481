import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { FeaturesData } from '../../constantData/FeaturesData'
import DropDownIcon from '../../images/icons/dropDown'
import RightArrowIcon from '../../images/icons/rightArrow'
import { getElementHegiht } from '../../utils/utils'
import FeaturesTabItem from '../common/FeaturesTabItem'
import SectionHeader from '../common/SectionHeader'


const Features:React.FC = () => {

    const featuresQueryData = useStaticQuery(graphql`
        query FeaturesComponentQuery {
            SlicedElipse: file(
            absolutePath: {regex: "/images/icons/"}
            name: {eq: "slicedElipse"}
            ) {
            childImageSharp {
                gatsbyImageData(width: 95, height: 95, quality:100, placeholder: TRACED_SVG)
            }
            }

        }
    
    `)


    const featuresData = FeaturesData()

    const [open, setOpen] = useState(false)

    const [currentFeature, setCurrentFeature] = useState(-1)


    // New code after seeing shopjar
    useEffect(()=> {        

        let timer = setInterval(()=> {
            if(currentFeature + 1 > featuresData.length -1) {
                setCurrentFeature(0)
            }else {
                setCurrentFeature(currentFeature + 1)
            }
        }, 10000)

        return () => {
            clearInterval(timer);
        }
    })

    useEffect(()=> {
        if(currentFeature === -1){
            setCurrentFeature(0)
            setOpen(true)
        }
    },[])

    const handleChangeFeature = (index: number) => {
        setCurrentFeature(index)
    }


    return (
        <>
            <section className="relative pt-5 lg:pt-16 lg:pb-20 bg-background1" id="features">

                {/* Header */}
                <SectionHeader 
                    title="Affordable Email Marketing Service"
                    description="An affordable email marketing software that democratises email marketing for everyone with the goal of simplifying and improving your whole email marketing process."
                    classNames="pb-10"
                />

                    <div className="hidden lg:flex w-4/5 mx-auto flex-col items-center">

                        {/* SLIDE ICON SECTION */}
                        <div className="w-full flex flex-row justify-center overflow-x-auto overflow-y-hidden">

                            {featuresData.map((feature, index) => {
                                return(
                                    <FeaturesTabItem 
                                        key={index}
                                        icon={feature.icon}
                                        title={feature.title}
                                        active={currentFeature === index}
                                        onClick={()=>handleChangeFeature(index)}
                                    />
                                )
                            })}
                        </div>

                        {/* SLIDE IMAGE SECTION */}
                        <div className="w-3/4 pt-10 pb-8 flex flex-col space-y-8 benefit-image-shadow">
                            <div>
                                {currentFeature >=0 &&
                                    <GatsbyImage image={featuresData.filter((data, index) => index === currentFeature)[0].image} alt="image" />
                                }
                            </div>

                            <p className="w-full mx-auto font-medium text-xl text-font_primary">
                                {featuresData.filter((data, index) => index === currentFeature)[0]?.content}
                            </p>
                        </div>

                    </div>
                    

                {/* Main Section For Mobile View */}
                <div className="block lg:hidden px-6 md:px-10">
                    
                    {featuresData.map((feature, index) => {
                        return(
                            <div className="py-1.5" key={index}>
                                <div className={`h-1 ${currentFeature === index ? 'bg-primary' : 'bg-background1' }`} style={{width: `${currentFeature === index ? "100%" : "0%"}`, transition: "width 10s ease-in-out"}}></div>
                                <div onClick={()=> {setCurrentFeature(index); setOpen(!open)}} className={`px-4 py-5 flex flex-row items-center justify-between  ${currentFeature === index ? "compare-gz-mobile-view-shadow" : ""}`}>
                                    <p className={`font-medium text-sm ${currentFeature === index ? "text-font_primary" : "text-font_secondary"}`}>{feature.title}</p>
                                    <DropDownIcon classNames={`${currentFeature === index && open ? "text-primary rotate-180" : "text-font_secondary rotate-0"} transform transition-all ease-in duration-300`} />
                                </div>

                                <div 
                                    style={{height: currentFeature === index && open ? getElementHegiht("#feature-accordion-"+index) : 0}}
                                    className={`transition-all duration-500 ease-in-out overflow-hidden `}
                                >
                                    <div id={`feature-accordion-${index}`} className="relative flex flex-col space-y-3">

                                        {currentFeature >=0 &&
                                            <GatsbyImage image={featuresData.filter((data, index) => index === currentFeature)[0].image} alt="image" />
                                        }
                                        
                                        <p className="text-sm pb-3 px-5 lg:pb-0 lg:px-0">
                                            {featuresData.filter((data, index) => index === currentFeature)[0]?.content}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        )
                    })}                    

                </div>
            </section>
        </>
    )
}

export default Features

import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { PricePlans, PricingConstantData } from '../../constantData/PricingData'
import PricingCard from '../common/PricingCard'
import SectionHeader from '../common/SectionHeader'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const Pricing:React.FC = () => {

    const pricingData = PricingConstantData()

    const pricingPlanData = PricePlans()

    const [range, setRange] = useState(0)

    const [plan, setPlan] = useState("")
    const [activePlan, setActivePlan] = useState<any>({
        emails: 15000,
        starter: '$9',
        advanced: '$29',
        premium: '$99',
        range: 10
    })

    const marks = {
        10: {
            style: {
                color: range === 10 ? '#06152D' : '#586474',
                fontWeight: 500,
                fontSize: 18,
                marginTop: -60
            },
            label: '20,000'
        },
        20: {
            style: {
                color: range === 20 ? '#06152D' : '#586474',
                fontWeight: 500,
                fontSize: 18,
                marginTop: -60
            },
            label: '40,000'
        },
        30: {
            style: {
                color: range === 30 ? '#06152D' : '#586474',
                fontWeight: 500,
                fontSize: 18,
                marginTop: -60
            },
            label: '100,000'
        },
        40: {
            style: {
                color: range === 40 ? '#06152D' : '#586474',
                fontWeight: 500,
                fontSize: 18,
                marginTop: -60
            },
            label: '300,000'
        },
        50: {
            style: {
                color: range === 50 ? '#06152D' : '#586474',
                fontWeight: 500,
                fontSize: 18,
                marginTop: -60
            },
            label: '700,000'
        },
        60: {
            style: {
                color: range === 60 ? '#06152D' : '#586474',
                fontWeight: 500,
                fontSize: 18,
                marginTop: -60
            },
            label: '1,000,000'
        },
        70: {
            style: {
                color: range === 70 ? '#06152D' : '#586474',
                fontWeight: 500,
                fontSize: 18,
                marginTop: -60
            },
            label: '2,000,000'
        },
        80: {
            style: {
                color: range === 80 ? '#06152D' : '#586474',
                fontWeight: 500,
                fontSize: 18,
                marginTop: -60
            },
            label: '1M and above '
        },
    }

    useEffect(()=> {
        let filter = pricingPlanData.filter((item) => item.emails == plan )[0]
        if(typeof filter !== 'undefined') setActivePlan(filter)
    }, [plan])

    useEffect(() => {
        let filter = pricingPlanData.filter((item) => item.range == range )[0]
        if(typeof filter !== 'undefined') setActivePlan(filter)
    }, [range])

    const handlePricingChange = (value:any) => {
        setRange(value)
    }

    const handleMobilePlanChange = (e:any) => {
        setPlan(e.target.value)
    }

    
    return (
        <>
            <section id="pricing" className="pt-5 pb-7 lg:pt-16 lg:pb-20 bg-background1">

                {/* Header */}
                <SectionHeader 
                    title="Affordable Email Marketing Service"
                    description="An affordable email marketing software that democratises email marketing for everyone with the goal of simplifying and improving your whole email marketing process."
                    classNames="pb-10"
                />

                {/* Range Slider Section for Desktop */}
                <div className="hidden lg:block w-11/12 mx-auto px-6 pt-4 pb-16">

                    <div className="text-font_primary text-2xl font-bold">How many emails a month do you need to send?</div>

                    <div className="w-full pt-20">
                    
                        <Slider
                            defaultValue={0}
                            trackStyle={{ backgroundColor: 'blue', height: 10, width: '100%', border: '1px solid #EBEDEF' }}
                            handleStyle={{
                            height: 30,
                            width: 30,
                            marginLeft: 0,
                            marginTop: -10,
                            backgroundColor: '#2563EB',
                            boxShadow: '0px 0px 22px rgba(11, 13, 63, 0.08)',
                            border: '3px solid #FFFFFF',
                            backgroundImage: `url('../../images/pricing/code.png')`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            }}
                            railStyle={{ backgroundColor: 'white', height: 10, width: '100%', border: '1px solid #EBEDEF' }}
                            min={0}
                            max={90}
                            step={10}
                            marks={marks}
                            dotStyle={{position: 'absolute', top: 1}}
                            activeDotStyle={{backgroundColor: 'white'}}
                            onChange={(e) => handlePricingChange(e)}
                        />
                    </div>
                </div>

                {/* Select Section for Mobile */}
                <div className="block lg:hidden w-11/12 px-6 pb-6 mx-auto space-y-4">
                    <div className="text-font_primary text-base font-bold">How many emails a month do you need to send?</div>

                    <select onChange={(e)=> {handleMobilePlanChange(e)}} className=" w-full mx-auto px-4 py-3 border border-border form-select focus:outline-none rounded-xl text-font_primary" name="pricing-plan" id="pricing-plan">
                        {pricingPlanData.map((plan, index)=> {
                            return(
                                <option key={index} value={plan.emails} className="capitalize text-font_primary">{plan.emails}</option>
                            )
                        })}
                    </select>
                </div>



                <div className="w-11/12 justify-items-center mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-3.5 gap-y-5">
                    {pricingData.map((item, index)=> {
                        return(
                            <PricingCard 
                                key={index}
                                plan={item.plan}
                                image={item.icon}
                                alt="Icon"                            
                                price={item.price}
                                description={item.description}
                                benefits={item.benefits}
                                activePlan={activePlan}
                            />
                        )
                    })                    
                    }
                </div>

            </section>
        </>
    )
}

export default Pricing

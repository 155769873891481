import React from 'react'

type DataProps = {
    title: string
    description: string
    backgroundColor?: string
    classNames?: string
}

const SectionHeader:React.FC<DataProps> = ({title, description, backgroundColor, classNames}) => {
    return (
        <div className={`text-center mx-auto space-y-2.5 ${backgroundColor} ${classNames}`}>
            <h1 className="w-4/5 mx-auto text-xl lg:text-2xl xl:text-3xl capitalize">{title}</h1>
            <p className="w-4/5 lg:w-2/5 mx-auto text-sm lg:text-base font-normal">{description}</p>
        </div>
    )
}

export default SectionHeader

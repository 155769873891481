import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"

export const BenifitsData = () => {
    const benifitsQueryData = useStaticQuery(graphql`
        query benifitsQueryData {
            Unique_solutions: file(
                absolutePath: {regex: "/images/benifits/"}
                name: {eq: "unique_solutions"}
            ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }

            Massive_library: file(
                absolutePath: {regex: "/images/benifits/"}
                name: {eq: "massive_library"}
            ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }

            Honenst_and_transparent: file(
                absolutePath: {regex: "/images/benifits/"}
                name: {eq: "honenst_and_transparent"}
            ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }
        }           
    `)

    return [
        {
            section: 'Unique Solutions',
            title: 'Industry-Specific Email Marketing Solution',
            content: "We've created an email marketing solution that's industry-specific and tailored to each marketing goal to increase customer value and revenue.",
            image: getImage(benifitsQueryData.Unique_solutions)
        },
        
        {
            section: 'Massive Library',
            title: 'Email Template library',
            content: "Massive template library of 400+ templates that are personalized to focus on your email marketing goals. you can directly import the templates from their template library. You're done once you've finished editing your content.",
            image: getImage(benifitsQueryData.Massive_library)
        },

        {
            section: 'Honest And Transparent',
            title: 'High Inbox Delivery Rate',
            content: 'Get your emails delivered to the right inboxes. Lucid Reach places great value on email deliverability',
            image: getImage(benifitsQueryData.Honenst_and_transparent)
        }
    ]
}
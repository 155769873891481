import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

export const FeaturesData= () => {

    const data = useStaticQuery(graphql`
        query Features {
            ContactImport: file(name: {eq: "Contact"}, absolutePath: {regex: "/images/features/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            Lists: file(name: {eq: "List"}, absolutePath: {regex: "/images/features/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            EmailTemplate: file(name: {eq: "Email"}, absolutePath: {regex: "/images/features/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            DragAndDrop: file(name: {eq: "Drag"}, absolutePath: {regex: "/images/features/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            CampaignOutreach: file(name: {eq: "Campign"}, absolutePath: {regex: "/images/features/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            SignupForms: file(name: {eq: "Signup"}, absolutePath: {regex: "/images/features/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            Teams: file(name: {eq: "Team"}, absolutePath: {regex: "/images/features/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            Analytics: file(name: {eq: "Analytics"}, absolutePath: {regex: "/images/features/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            ImportIcon: file(name: {eq: "import"}, absolutePath: {regex: "/images/features/icons/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: BLURRED)
                }
            }

            ListIcon: file(name: {eq: "list"}, absolutePath: {regex: "/images/features/icons/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: BLURRED)
                }
            }

            EmailTemplateIcon: file(name: {eq: "template"}, absolutePath: {regex: "/images/features/icons/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: BLURRED)
                }
            }

            DragIcon: file(name: {eq: "drag"}, absolutePath: {regex: "/images/features/icons/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: BLURRED)
                }
            }

            OutreachIcon: file(name: {eq: "outreach"}, absolutePath: {regex: "/images/features/icons/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: BLURRED)
                }
            }

            SignupIcon: file(name: {eq: "signup"}, absolutePath: {regex: "/images/features/icons/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: BLURRED)
                }
            }

            TeamIcon: file(name: {eq: "team"}, absolutePath: {regex: "/images/features/icons/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: BLURRED)
                }
            }

            AnalyticIcon: file(name: {eq: "analytics"}, absolutePath: {regex: "/images/features/icons/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: BLURRED)
                }
            }
        }      
    `)

    return [
        {
            title: 'Contact Import',
            value: 'contact_import',
            icon: getImage(data.ImportIcon),
            image: getImage(data.ContactImport),
            content: `Expand your mailing list. Connect to the tool you're using and import your contacts to start sending out email campaigns. The data in your import file will be matched to contact attributes in your Lucid Reach account.`
        },

        {
            title: 'Lists',
            value: 'lists',
            icon: getImage(data.ListIcon),
            image: getImage(data.Lists),
            content: 'Maintain your email contact lists clean and sorted by the type of contacts and help to filter and find any contact in just a few clicks  Manage your contacts systematically, saves you more time to focus on your business development.'
        },

        {
            title: 'Email template library',
            value: 'email_template_library',
            icon: getImage(data.EmailTemplateIcon),
            image: getImage(data.EmailTemplate),
            content: 'Industry-specific email marketing templates with a template library of 400+ templates can be customized to focus on your email marketing and sales goals. Send email campaigns that stand out in today’s crowded inboxes.'
        },

        {
            title: 'Drag & Drop tool',
            value: 'drag_and_drop_tool',
            icon: getImage(data.DragIcon),
            image: getImage(data.DragAndDrop),
            content: 'Email templates can be easily modified using the easy-to-use drag-and-drop email builder , thus designing informative and engaging emails that require no coding.'
        },

        {
            title: 'Campaign Outreach',
            value: 'campaign_outreach',
            icon: getImage(data.OutreachIcon),
            image: getImage(data.CampaignOutreach),
            content: 'You can customize your email campaign to suit all your needs. In under 5 minutes, create a campaign - choose your industry and goal - setup and personalize your content - Schedule and send out campaigns. '
        },

        {
            title: 'Signup forms',
            value: 'Signup forms',
            icon: getImage(data.SignupIcon),
            image: getImage(data.SignupForms),
            content: 'Generate more leads and create a customer mailing list using our sign-up forms. You can use pre-built layouts and embed them on your site without having to code anything.'
        },

        {
            title: 'Teams',
            value: 'teams',
            icon: getImage(data.TeamIcon),
            image: getImage(data.Teams),
            content: 'Members can invite other members enhanced conversion rates by allowing team members to collaborate and create new leads.'
        },

        {
            title: 'Analytics and Report Tracking',
            value: 'analytics_and_Report_Tracking',
            icon: getImage(data.AnalyticIcon),
            image: getImage(data.Analytics),
            content: `Keep track of all of your account's various data points in one place.  You can track average click through rate, email open rate, email bounce rate and your engagement in real-time.`
        }
    ]
}

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'


export const PricingConstantData= () => {

    const data = useStaticQuery(graphql`
        query Pricing {
            FreeImage: file(name: {eq: "free"}, absolutePath: {regex: "/images/pricing/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            StarterImage: file(name: {eq: "starter"}, absolutePath: {regex: "/images/pricing/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            AdvancedImage: file(name: {eq: "advanced"}, absolutePath: {regex: "/images/pricing/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            PremiumImage: file(name: {eq: "premium"}, absolutePath: {regex: "/images/pricing/"}) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
        }      
    `)

    const freeImage = getImage(data.FreeImage)
    const starterImage = getImage(data.StarterImage)
    const advancedImage = getImage(data.AdvancedImage)
    const premiumImage = getImage(data.PremiumImage)

    return [
        {
            plan: 'Free',
            description: 'Run your company on your terms',
            price: '$0',
            icon: freeImage,
            benefits: [
                '2000 contacts',
                'Drag and Drop Editor',
                '400+ Customizable Html Templates',
                '400+ Plain Text Email Templates',
                'Email Campaign Reports',
                'Mobile Friendly Email Templates',
                'Email Sheduling',
                'Integrations',
                'Industry Specific Email Marketing',
                'Subscriber Management',
                'Signup Forms',
                'Multi User Access'
            ]
        },

        {
            plan: 'Starter',
            description: 'Run your company on your terms',
            price: '$9',
            icon: starterImage,
            benefits: [
                '10000 contacts',
                'Drag and Drop Editor',
                '400+ Customizable Html Templates',
                '400+ Plain Text Email Templates',
                'Email Campaign Reports',
                'Mobile Friendly Email Templates',
                'Email Sheduling',
                'Integrations',
                'Industry Specific Email Marketing',
                'Subscriber Management',
                'Signup Forms',
                'Multi User Access',
                '24 Hrs Email Support',
            ]
        },

        {
            plan: 'Advanced',
            description: 'Run your company on your terms',
            price: '$29',
            icon: advancedImage,
            benefits: [
                'Unlimited contacts',
                'Drag and Drop Editor',
                '400+ Customizable Html Templates',
                '400+ Plain Text Email Templates',
                'Email Campaign Reports',
                'Mobile Friendly Email Templates',
                'Email Sheduling',
                'Integrations',
                'Industry Specific Email Marketing',
                'Subscriber Management',
                'Signup Forms',
                'Multi User Access',
                '12 Hrs Chat & Email Support',
            ]
        },

        {
            plan: 'Premium',
            description: 'Run your company on your terms',
            price: '$99',
            icon: premiumImage,
            benefits: [
                'Unlimited contacts',
                'Drag and Drop Editor',
                '400+ Customizable Html Templates',
                '400+ Plain Text Email Templates',
                'Email Campaign Reports',
                'Mobile Friendly Email Templates',
                'Email Sheduling',
                'Integrations',
                'Industry Specific Email Marketing',
                'Subscriber Management',
                'Signup Forms',
                'Multi User Access',
                'Chat, Email & Dedicated A/C Manage',
            ]
        }
    ]
}

export const PricePlans = () => {

    return [
        {
            emails: 15000,
            starter: '$9',
            advanced: '$29',
            premium: '$99',
            range: 0
        },

        {
            emails: 20000,
            starter: '$22',
            advanced: '$60',
            premium: '$190',
            range: 10
        },

        {
            emails: 40000,
            starter: '$41',
            advanced: '$90',
            premium: '$230',
            range: 20
        },

        {
            emails: 100000,
            starter: '$87',
            advanced: '$146',
            premium: '$296',
            range: 30
        },

        {
            emails: 300000,
            starter: '$161',
            advanced: '$230',
            premium: '$390',
            range: 40
        },

        {
            emails: 700000,
            starter: '$338',
            advanced: '$428',
            premium: '$588',
            range: 50
        },

        {
            emails: 1000000,
            starter: '$354',
            advanced: '$443',
            premium: '$623',
            range: 60
        },

        {
            emails: 2000000,
            starter: '$502',
            advanced: '$631',
            premium: '$401',
            range: 70
        },

        {
            emails: '1M and above',
            starter: 'Consult',
            advanced: 'Consult',
            premium: 'Consult',
            range: 80
        },
    ]
}

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import RightArrowIcon from '../../images/icons/rightArrow'

type DataProps = {
    benifit: {
        section: string,
        title: string,
        image: any,
        content: string
    },
    even: boolean
}

const BenifitItem:React.FC<DataProps> = ({benifit, even = false}) => {
    return (
        <>
            <div className={`w-full mx-auto ${even ? 'bg-white' : 'bg-background1'}`}>
                <div className={` w-full lg:w-4/5 py-7 lg:py-16 mx-auto flex flex-col items-center`}>

                    <div className="w-4/5 mx-auto space-y-2 text-center ">
                        <p className="text-xs md:text-sm lg:text-base text-primary font-bold uppercase">{benifit.section}</p>

                        <h1 className="text-xl md:text-2xl lg:text-3xl">{benifit.title}</h1>

                        <h3 className="w-auto lg:w-3/4 mx-auto text-sm md:text-base lg:text-lg font-normal text-font_secondary">{benifit.content}</h3>
                    </div>

                    <div className="w-full px-10 lg:px-0 lg:w-3/4 py-7 benefit-image-shadow">
                        <GatsbyImage image={benifit.image} alt={benifit.title} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BenifitItem

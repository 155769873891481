import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import CompareLucidReach from "../components/IndexPage/CompareLucidReach"
import Features from "../components/IndexPage/Features"
import Benifits from "../components/IndexPage/Benifits"
import Contact from "../components/IndexPage/Contact"
import Pricing from "../components/IndexPage/Pricing"

const IndexPage:React.FC = () => {


  return(
    <Layout>
      <Seo title="Home" />

        {/* COMPARE LUCID REACH SECTION */}
        <CompareLucidReach />

        {/* FEATURES */}
        <Features />

        {/* BENIFITS */}
        <Benifits />

        {/* Pricing */}
        <Pricing />

        {/* Contact */}
        {/* <Contact /> */}

    </Layout>
  )
}

export default IndexPage

import React from 'react'

const TickIcon = () => {
    return (
        <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.20678 12.7927C7.81626 13.1833 7.18309 13.1833 6.79257 12.7927L4.58301 10.5832C4.26084 10.261 3.73851 10.261 3.41634 10.5832C3.09418 10.9053 3.09417 11.4277 3.41634 11.7498L6.79257 15.1261C7.18309 15.5166 7.81626 15.5166 8.20678 15.1261L16.9163 6.4165C17.2385 6.09434 17.2385 5.572 16.9163 5.24984C16.5942 4.92767 16.0718 4.92767 15.7497 5.24984L8.20678 12.7927Z" fill="#2BC06A"/>
            </svg>
        </div>
    )
}

export default TickIcon

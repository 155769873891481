
export const getElementHegiht = (ele) => {
    try{
        const element = document.querySelector(ele)
        return element ? element.clientHeight : "auto"
    }catch (err) {
        return "auto"
    }
}

export const getElementWidth = (ele) => {
    try{
        const element = document.querySelector(ele)
        return element ? element.clientWidth : "auto"
    }catch (err) {
        return "auto"
    }
}

import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'
import Button from './Button'
import TickIcon from '../../images/icons/Tick'

type DataProps = {
    image: any,
    alt: string,
    plan: string,
    price: string,
    description: string,
    benefits: string[],
    activePlan: any
}

const PricingCard:React.FC<DataProps> = ({image, alt, plan, price, description, benefits, activePlan}) => {

    return (
        <>
            <div className={`w-72 md:w-80 lg:w-96 xl:w-72 2xl:w-80 3xl:w-96 px-6 pb-7 md:pb-5 h-full rounded-3xl bg-white ${plan === 'Starter' && 'pricing-card-highlight-shadow'}`}>

                {/* Icon section */}
                <div className="py-7 flex flex-row justify-between items-center">
                    <GatsbyImage image={image} alt={alt} />

                    {plan === 'Advanced' && 
                        <div className="w-auto px-3.5 py-2 text-sm text-primary font-medium bg-primary bg-opacity-10 rounded-lg">
                            Recommended
                        </div>
                    }
                </div>

                {/* Header Section */}
                <div className="">  
                    <h3 className="text-lg lg:text-xl text-font_primary font-medium uppercase">{plan}</h3>
                    {/* <p className=" text-sm lg:text-base text-font_secondary">{description}</p> */}
                </div>

                {/* Price Section */}
                <div className="pt-1 flex flex-col justify-start items-start space-y-1">
                    <p className="text-font_primary text-sm lg:text-base font-normal antialiased">Starts at</p>
                    <div className="text-font_primary text-3xl lg:text-40px font-bold leading-10">
                        {
                            plan === 'Free' ? '$0' 
                            :
                            plan === 'Starter' ? (activePlan.starter ? activePlan.starter : price)
                            :
                            plan === 'Advanced' ? (activePlan.advanced ? activePlan.advanced : price)
                            :
                            plan === 'Premium' ? (activePlan.premium ? activePlan.premium : price)
                            :
                            null
                        }
                    </div>
                    <p className="text-sm lg:text-base text-font_secondary ">/month</p>
                </div>

                {/* Button Section */}
                <div className="py-6">
                    <Button text={plan === 'Free' ? 'Current Plan' : 'Choose Plan'} background={plan === 'Free' ? 'bg-white' : 'bg-primary'} width="w-full" borderRadius="rounded-xl" textColor={plan === 'Free' ? 'text-font_secondary font-medium' : 'text-white'} classNames={`${plan === 'Free' && 'border border-border'}`} />
                </div>
                
                {/* Content Section */}
                <div className="space-y-6">
                    <div className="flex flex-row items-center justify-start space-x-2">
                        <TickIcon />
                        <p className="font-medium text-font_primary text-sm ">
                            <span className="font-bold">{plan === 'Free' ? '15,000 emails' : activePlan.emails ? activePlan.emails : '15,000 emails'}</span> / month
                        </p>
                    </div>
                    {benefits.map((benefit,index) => {
                        return(
                            <div key={index} className="flex flex-row items-center justify-start space-x-2">
                                <TickIcon />
                                <p className="font-medium text-font_primary text-sm ">{benefit}</p>
                            </div>
                        )
                    })} 
                </div>               

            </div>
        </>
    )
}

export default PricingCard

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

type DataProps = {
    icon: any
    title: string,
    active: boolean,
    onClick: () => void
}


const FeaturesTabItem:React.FC<DataProps> = ({icon, title, active=false, onClick}) => {
    return (
        <>
            <div onClick={onClick} className={`w-145px h-136px px-3 flex flex-col items-center space-y-3 rounded-lg break-words text-center ${active ? 'bg-white active-feature-shadow' : '' } cursor-pointer`}>
                <div className="pt-6"><GatsbyImage image={icon} alt={title} className={`w-12 h-12 filter ${active ?  'saturate-100' : 'saturate-40'}`}  /></div>
                <p className={`${active ? 'text-font_primary' : 'text-font_secondary' } font-medium text-sm`}>{title}</p>
            </div>
        </>
    )
}

export default FeaturesTabItem

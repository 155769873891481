import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { CompareDataDesktop, CompareDataMobile } from '../../constantData/CompareData'
// import TickSquare from '../../images/icons/tick_square'
import SectionHeader from '../common/SectionHeader'

const CompareLucidReach:React.FC = () => {

    const data = useStaticQuery(graphql`
        query CompareLucidReach {
            LucidReach: file(absolutePath: {regex: "/images/other_products/"}, name: {eq: "lucidreach"}) {
                id
                childImageSharp {
                    gatsbyImageData(width:118, quality: 100, placeholder: NONE)
                }
            }    

            ConstantContact: file(absolutePath: {regex: "/images/other_products/"}, name: {eq: "constantcontact"}) {
                id
                childImageSharp {
                    gatsbyImageData(width:118, quality: 100, placeholder: NONE)
                }
            }

            Mailer: file(absolutePath: {regex: "/images/other_products/"}, name: {eq: "mailer"}) {
                id
                childImageSharp {
                    gatsbyImageData(width:118, quality: 100, placeholder: NONE)
                }
            }

            Aweber: file(absolutePath: {regex: "/images/other_products/"}, name: {eq: "aweber"}) {
                id
                childImageSharp {
                    gatsbyImageData(width:118, quality: 100, placeholder: NONE)
                }
            }

            Drip: file(absolutePath: {regex: "/images/other_products/"}, name: {eq: "drip"}) {
                id
                childImageSharp {
                    gatsbyImageData(width:118, quality: 100, placeholder: NONE)
                }
            }

            Mailchimp: file(absolutePath: {regex: "/images/other_products/"}, name: {eq: "mailchimp"}) {
                id
                childImageSharp {
                    gatsbyImageData(width:118, quality: 100, placeholder: NONE)
                }
            }

            TickSquare: file(absolutePath: {regex: "/images/icons/"}, name: {eq: "tick_square"}) {
                id
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            CloseSquare: file(absolutePath: {regex: "/images/icons/"}, name: {eq: "close_square"}) {
                id
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

            ElipseGrid: file(absolutePath: {regex: "/images/icons/"}, name: {eq: "elipsegrid"}) {
                id
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }

        }
    `
    )

    const [screenHeight, setScreenHeight] = useState(0)

    React.useEffect(()=> {
        setScreenHeight(screen.height);
    })


    const lucidreachImage = getImage(data.LucidReach)    
    const constantcontactImage = getImage(data.ConstantContact)
    const mailerImage = getImage(data.Mailer)
    const aweberImage = getImage(data.Aweber)
    const dripImage = getImage(data.Drip)
    const mailchimpImage = getImage(data.Mailchimp)

    const tickSquare = getImage(data.TickSquare)
    const closeSquare = getImage(data.CloseSquare)
    
    const compareLucidReachData = CompareDataDesktop()
    const compareLucidReachDataMobile = CompareDataMobile()

    return (
        <section id="compare-us" className="relative py-10 lg:py-20">

            {/* Header Section */}
            <div className={`pb-10 ${screenHeight < 570 ? 'pt-24' : 'pt-0'} md:pt-48 xl:pt-60`}>
                <SectionHeader 
                    title="Put an end to paying more to grow your business."
                    description="We offer the most cost-effective option among email marketing platforms."
                />
            </div>

            {/* Table section for desktop view */}
            <div className="hidden lg:block relative w-11/12 mx-auto bg-white border rounded-2xl z-10" >

                <table className="w-full">
                    <thead>
                        <tr className="h-20 bg-background2">
                            <th className='w-1/4 text-left px-8 text-lg text-font_primary font-medium'>Features</th>
                            <th className='w-12.5% px-2 growthzen-highlight-shadow'><GatsbyImage image={lucidreachImage} alt="LucidReach Logo" /></th>
                            <th className='w-12.5% px-2'><GatsbyImage image={constantcontactImage} alt="ConstantContact Logo" /></th>
                            <th className='w-12.5% px-2'><GatsbyImage image={mailerImage} alt="Mailer Logo" /></th>
                            <th className='w-12.5% px-2 pt-3'><GatsbyImage image={aweberImage} alt="Aweber Logo" /></th>
                            <th className='w-12.5% px-2 pt-1.5'><GatsbyImage image={dripImage} alt="Drip Logo" /></th>
                            <th className='w-12.5% px-2 pt-1.5'><GatsbyImage image={mailchimpImage} alt="Mailchimp Logo" /></th>
                        </tr>
                    </thead>

                    <tbody className="">
                        {compareLucidReachData.map(data => {
                            return(
                                <tr key={data.name}>
                                    <td className="w-1/4 text-left px-8 py-6 font-normal text-font_primary antialiased">{data.name}</td>
                                    <td className="w-12.5% text-center growthzen-highlight-shadow">
                                        {typeof data.lucidreach === 'boolean' ? 
                                            <GatsbyImage image={data.lucidreach ? tickSquare : closeSquare} alt={data.lucidreach ? "Tick True" : "Close True"}  className="w-7 h-7" />
                                            :
                                            <p className="text-font_primary font-medium text-lg">{data.lucidreach}</p>
                                        }                                        
                                    </td>
                                    <td className="w-12.5% text-center"><GatsbyImage image={data.constantcontact ? tickSquare : closeSquare} alt={data.constantcontact ? "Tick True" : "Close True"}  className="w-7 h-7" /></td>
                                    <td className="w-12.5% text-center"><GatsbyImage image={data.mailer ? tickSquare : closeSquare} alt={data.mailer ? "Tick True" : "Close True"}  className="w-7 h-7" /></td>
                                    <td className="w-12.5% text-center"><GatsbyImage image={data.aweber ? tickSquare : closeSquare} alt={data.aweber ? "Tick True" : "Close True"} className="w-7 h-7"  /></td>
                                    <td className="w-12.5% text-center"><GatsbyImage image={data.drip ? tickSquare : closeSquare} alt={data.drip ? "Tick True" : "Close True"}  className="w-7 h-7" /></td>
                                    <td className="w-12.5% text-center"><GatsbyImage image={data.mailchimp ? tickSquare : closeSquare} alt={data.mailchimp ? "Tick True" : "Close True"}  className="w-7 h-7" /></td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td className="w-1/4 text-left px-8 py-6 font-normal text-font_primary antialiased">Pricing</td>
                            <td className="w-12.5% text-center py-6  growthzen-highlight-shadow">
                                <p className="text-2xl text-font_primary font-medium text-center">$9</p>
                            </td>

                            <td className="w-12.5% text-center break-words px-2 py-6">
                                <p className="text-lg text-font_primary font-medium text-center">$17</p>
                                <p className="text-13px text-font_secondary font-normal text-center leading-4">[ pay additional for contacts ] </p>
                            </td>

                            <td className="w-12.5% text-center break-words px-2 py-6">
                                <p className="text-lg text-font_primary font-medium text-center">$50</p>
                                <p className="text-13px text-font_secondary font-normal text-center leading-4">[ pay additional for contacts ] </p>
                            </td>

                            <td className="w-12.5% text-center break-words px-2 py-6">
                                <p className="text-lg text-font_primary font-medium text-center">$16</p>
                                <p className="text-13px text-font_secondary font-normal text-center leading-4">[ pay additional for contacts ] </p>
                            </td>

                            <td className="w-12.5% text-center break-words px-2 py-6">
                                <p className="text-lg text-font_primary font-medium text-center">$29</p>
                                <p className="text-13px text-font_secondary font-normal text-center leading-4">[ pay additional for contacts ] </p>
                            </td>

                            <td className="w-12.5% text-center break-words px-2 py-6">
                                <p className="text-lg text-font_primary font-medium text-center">$10</p>
                                <p className="text-13px text-font_secondary font-normal text-center leading-4">[ pay additional for contacts ] </p>
                            </td>
                        </tr>

                        <tr>
                            <td className="w-1/4 text-left px-8 py-6 font-normal text-font_primary antialiased">Contact pricing </td>

                            <td className="w-12.5% text-center break-words px-2 py-6 growthzen-highlight-shadow">
                                <p className="text-lg text-font_primary font-medium text-center">$9</p>
                                <p className="text-13px text-font_secondary font-normal text-center leading-4">for unlimited contacts</p>
                            </td>

                            <td className="w-12.5% text-center break-words px-2 py-6">
                                <p className="text-lg text-font_primary font-medium text-center">$20</p>
                                <p className="text-13px text-font_secondary font-normal text-center leading-4">additionally for every 2000 contacts</p>
                            </td>

                            <td className="w-12.5% text-center break-words px-2 py-6">
                                <p className="text-lg text-font_primary font-medium text-center">$35</p>
                                <p className="text-13px text-font_secondary font-normal text-center leading-4">additionally for every 1500 contacts</p>
                            </td>

                            <td className="w-12.5% text-center break-words px-2 py-6">
                                <p className="text-lg text-font_primary font-medium text-center">$10</p>
                                <p className="text-13px text-font_secondary font-normal text-center leading-4">additionally for every 1500 contacts</p>
                            </td>

                            <td className="w-12.5% text-center break-words px-2 py-6">
                                <p className="text-lg text-font_primary font-medium text-center">$10</p>
                                <p className="text-13px text-font_secondary font-normal text-center leading-4">additionally for every 1500 contacts</p>
                            </td>

                            <td className="w-12.5% text-center break-words px-2 py-6">
                                <p className="text-lg text-font_primary font-medium text-center">$35</p>
                                <p className="text-13px text-font_secondary font-normal text-center leading-4">additionally for every 1500 contacts</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

            {/* Table section for mobile view */}
            <div className="relative block lg:hidden mx-6 md:mx-10 bg-white border border-border rounded-2xl compare-gz-mobile-view-shadow">

                    {compareLucidReachDataMobile.map((product, index) => { 
                        
                        return(

                            <div key={index} className="px-5 pt-6 md:px-8 md:pt-8 space-y-4">
                                <div className="flex flex-row items-center justify-between">
                                    <GatsbyImage image={product.image} alt="LucidReach_logo" />
                                    <p className="font-bold text-15px md:text-base text-font_primary">{product.price}</p>
                                </div>

                                <div className="flex flex-col justify-between pb-3 border-b border-border">

                                    {delete product.name}
                                    {delete  product.image}
                                    {Object.keys(product).map((obj, index) => {
                                        return(
                                            <div key={index} className="flex flex-row items-center justify-between">
                                                {(obj !== 'price' && obj !== 'contactPricing') ? <p className="text-13px md:text-15px font-medium text-font_primary">{obj}</p> :  null}                                                
                                                {
                                                    (obj !== 'price' && obj !== 'contactPricing') ?
                                                    
                                                    obj === 'Plain text templates' ? 
                                                    typeof product[obj] === 'boolean' ? 
                                                        <GatsbyImage image={product[obj] ? tickSquare : closeSquare} alt={product[obj] ? "Tick True" : "Close True"}  className="w-4 h-4" />
                                                        : <p className="text-sm font-medium text-font_primary antialiased">{product[obj]}</p>
                                                    :
                                                    <GatsbyImage image={product[obj] ? tickSquare : closeSquare} alt={product[obj] ? "Tick True" : "Close True"}  className="w-4 h-4" />  
                                                    :
                                                    null
                                                }
                                            </div>
                                        )
                                    })}

                                    <div className='py-3'>
                                        <p className="text-xs text-font_primary font-meidum">Note: </p>
                                        <ul className='list-inside'> 
                                            <li className="px-2 list-disc text-xs text-font_secondary font-normal antialiased capitalize">{product.contactPricing}</li>
                                        </ul>
                                    </div>

                                </div>
                                
                            </div>   
                        )

                        })
                    }

            </div>


        </section>
    )
}

export default CompareLucidReach
